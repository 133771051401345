












































































































import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'

@Component({
  components: {
    Header,
    Content
  }
})
export default class Imprint extends Vue {}
